import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import Modal from "react-modal";
import { jwtDecode } from "jwt-decode"; // Install jwt-decode for decoding JWT tokens
import API_BASE_URL from "../../ApiConfig/ApiConfig";
import Cookies from "js-cookie";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
// Set the app element for accessibility
Modal.setAppElement("#root");

const ViewSoftware = () => {
  const [softwareList, setSoftwareList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedSoftware, setSelectedSoftware] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] =
    useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [unauthorizedModalIsOpen, setUnauthorizedModalIsOpen] = useState(false); // State for unauthorized modal

  const [userRole, setUserRole] = useState(null); // State for storing user role

  // Refs for modals
  const [editFormData, setEditFormData] = useState({ password: "" });
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

  const handlePasswordToggle = () => {
    setPasswordVisible(!passwordVisible); // Toggle password visibility
  };

  const modalRef = useRef(null);
  const editFormRef = useRef(null);

  useEffect(() => {
    // Function to retrieve and decode token
    const fetchUserRole = () => {
      const token = Cookies.get("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role); // Set the user's role from the token
      }
    };

    fetchUserRole();

    const fetchSoftware = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/software/all`);
        // Sort the software list alphabetically by software name
        const sortedSoftware = response.data.sort((a, b) =>
          a.softwareName.localeCompare(b.softwareName, undefined, {
            sensitivity: "base",
          })
        );
        setSoftwareList(sortedSoftware);
        setLoading(false);
      } catch (err) {
        setError("Error fetching software records.");
        setLoading(false);
      }
    };

    fetchSoftware();
  }, []);

  const openModal = (software) => {
    setSelectedSoftware(software);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSoftware(null);
  };

  const openConfirmDeleteModal = (software) => {
    setSelectedSoftware(software);
    setConfirmDeleteModalIsOpen(true);
  };

  const closeConfirmDeleteModal = () => {
    setConfirmDeleteModalIsOpen(false);
    setSelectedSoftware(null);
  };

  const openEditModal = (software) => {
    setSelectedSoftware(software);
    setEditFormData({
      softwareName: software.softwareName,
      amount: software.amount,
      purchaseDate: formatDate(software.purchaseDate),
      expiryDate: formatDate(software.expiryDate),
      userId: software.userId,
      password: software.password,
      additionalInfo: software.additionalInfo,
    });
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedSoftware(null);
  };

  const openUnauthorizedModal = () => {
    setUnauthorizedModalIsOpen(true);
  };

  const closeUnauthorizedModal = () => {
    setUnauthorizedModalIsOpen(false);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${API_BASE_URL}/software/delete/${selectedSoftware._id}`
      );
      setSoftwareList(
        softwareList.filter((software) => software._id !== selectedSoftware._id)
      );
      closeConfirmDeleteModal();
    } catch (error) {
      setError("Error deleting software record.");
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${API_BASE_URL}/software/update/${selectedSoftware._id}`,
        editFormData
      );
      const updatedData = await axios.get(`${API_BASE_URL}/software/all`);
      setSoftwareList(updatedData.data);
      closeEditModal();
      alert("Software record updated successfully.");
    } catch (error) {
      setError("Error updating software record.");
      alert("Error updating software record.");
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleClickOutside = useCallback(
    (e) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(e.target) &&
        modalIsOpen
      ) {
        closeModal();
      }
      if (
        editFormRef.current &&
        !editFormRef.current.contains(e.target) &&
        editModalIsOpen
      ) {
        closeEditModal();
      }
    },
    [modalIsOpen, editModalIsOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  const handleUnauthorizedAction = () => {
    openUnauthorizedModal(); // Open unauthorized access modal
  };

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 6; // Change this value to adjust how many records per page
  const totalPages = Math.ceil(softwareList.length / recordsPerPage);

  // Calculate the current records based on current page
  const startIndex = (currentPage - 1) * recordsPerPage;
  const filteredRecords = softwareList.slice(
    startIndex,
    startIndex + recordsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleAddClientClick = () => {
    if (userRole === "social" || userRole === "admin") {
      // Redirect to the /AddEvent page
      window.location.href = "/AddSoftware";
    } else if (userRole === "user" || userRole === "web") {
      // Show unauthorized message
      setUnauthorizedModalIsOpen(true);
    } else {
      console.error("User role not defined");
    }
  };
  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error)
    return <div className="text-center py-4 text-red-600">{error}</div>;
  return (
    <div
      className="min-w-full mx-auto p-6 mt-4 bg-white rounded-2xl shadow-md "
      style={{ borderRadius: "30px" }}
    >
      <div>
        <div className="flex items-center justify-between mt-4 mb-4">
        <h2 className="text-2xl font-bold text-gray-700 mb-2">
          Software Details
        </h2>
        <button
          onClick={handleAddClientClick}
          className="bg-[#006CB5] text-white font-bold py-2 px-4 rounded-md shadow-md hover:bg-[#0056a3] transition duration-300"
        >
          Add software
        </button>
        </div>
        <hr className="border-t mt-5 border-gray-300 mx-[-24px]" />
        <div className="overflow-x-auto">
          <table className="w-full bg-white rounded-md">
            <thead className="text-gray-400">
              <tr>
                <th className="p-2 sm:p-4 border-b text-left text-xs sm:text-sm">
                  Software Name
                </th>
                <th className="p-2 sm:p-4 border-b text-left text-xs sm:text-sm">
                  Purchase Date
                </th>
                <th className="p-2 sm:p-4 border-b text-left text-xs sm:text-sm">
                  Expiry Date
                </th>
                <th className="p-2 border-b pl-16 text-left text-xs sm:text-sm ">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredRecords.length === 0 ? (
                <tr>
                  <td
                    colSpan="4"
                    className="py-2 sm:py-4 px-2 sm:px-6 text-center text-gray-500 text-xs sm:text-sm"
                  >
                    No records found
                  </td>
                </tr>
              ) : (
                filteredRecords.map((software) => (
                  <tr key={software._id} className="hover:bg-gray-50">
                    <td className="p-2 sm:p-4 border-b font-semibold  text-[#292D32] text-left text-xs sm:text-sm">
                      {software.softwareName}
                    </td>
                    <td className="p-2 sm:p-4 border-b font-semibold  text-[#292D32] text-left text-xs sm:text-sm">
                      {new Date(software.purchaseDate).toLocaleDateString(
                        "en-US",
                        { month: "long", day: "numeric", year: "numeric" }
                      )}
                    </td>
                    <td className="p-2 sm:p-4 border-b  font-semibold  text-[#292D32]  text-left text-xs sm:text-sm">
                      {new Date(software.expiryDate).toLocaleDateString(
                        "en-US",
                        { month: "long", day: "numeric", year: "numeric" }
                      )}
                    </td>
                    <td className=" sm:py-3 px-2 sm:px-2 border-b text-left">
                      <div className="flex justify-evenly  pr-10 ">
                        <td className="   space-x-4">
                          <button
                            onClick={() => {
                              if (
                                userRole === "admin" ||
                                userRole === "social"
                              ) {
                                openModal(software);
                              } else {
                                handleUnauthorizedAction();
                              }
                            }}
                            className=" rounded hover:bg-sky-200"
                          >
                            <i className="fas fa-eye text-sm text-blue-500"></i>
                          </button>
                          <button
                            onClick={() => {
                              if (
                                userRole === "admin" ||
                                userRole === "social"
                              ) {
                                openEditModal(software);
                              } else {
                                handleUnauthorizedAction();
                              }
                            }}
                            className=" rounded hover:bg-green-200"
                          >
                            <i className="fas fa-edit text-sm text-[#008767]"></i>
                          </button>
                          <button
                            onClick={() => {
                              if (
                                userRole === "admin" ||
                                userRole === "social"
                              ) {
                                openConfirmDeleteModal(software);
                              } else {
                                handleUnauthorizedAction();
                              }
                            }}
                            className=" rounded hover:bg-red-200"
                          >
                            <i className="fas fa-trash-alt text-sm text-red-500"></i>
                          </button>
                        </td>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination Component */}
        <div className="flex justify-between items-center mt-4">
          <p className="text-xs text-gray-600">
            Showing {startIndex + 1} to{" "}
            {Math.min(startIndex + recordsPerPage, softwareList.length)} of{" "}
            {softwareList.length} entries
          </p>
          <div className="flex space-x-2">
            {/* Previous button */}
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-2 py-1 bg-gray-200 font-bold rounded-md text-xs"
            >
              {"<"}
            </button>

            {/* Page numbers with ellipsis */}
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter((page) => {
                return (
                  page === 1 || // Always show the first page
                  page === totalPages || // Always show the last page
                  page <= 3 ||
                  (page >= currentPage - 1 && page <= currentPage + 1) // Show pages around current page
                );
              })
              .map((page, index, filteredPages) => (
                <React.Fragment key={page}>
                  {index > 0 && page !== filteredPages[index - 1] + 1 && (
                    <span className="px-2 py-1 text-xs">...</span> // Show ellipsis for non-consecutive pages
                  )}
                  <button
                    onClick={() => handlePageChange(page)}
                    className={`px-2 py-1 rounded-md text-xs ${
                      currentPage === page
                        ? "bg-[#006CB5] text-white"
                        : "bg-gray-200"
                    }`}
                  >
                    {page}
                  </button>
                </React.Fragment>
              ))}

            {/* Next button */}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-2 py-1 font-bold bg-gray-200 rounded-md text-xs"
            >
              {">"}
            </button>
          </div>
        </div>

        {/* Modal for viewing software details */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="flex items-center justify-center p-4 h-full w-full z-50"
          overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 z-40"
          contentLabel="Software Details"
        >
          {selectedSoftware && (
            <div
              ref={modalRef}
              className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full mx-auto" // Changed max-w-xl to max-w-3xl
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-800">
                  Software Info
                </h2>
                <button
                  onClick={closeModal}
                  className="border border-gray-400 rounded-full w-6 h-6 flex items-center justify-center text-gray-600 hover:text-gray-800 hover:border-gray-600"
                >
                  <i className="fas fa-times text-lg"></i> {/* X icon */}
                </button>
              </div>
              <hr className="border-t border-gray-300 mx-[-24px] mb-4" />

              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex ">
                    <strong className="text-[#006CB5]">Software Name:</strong>
                    <span className="ml-2">
                      {selectedSoftware.softwareName}
                    </span>
                  </div>
                  <div className="flex ml-4">
                    <strong className="text-[#006CB5]">Software Amount:</strong>
                    <span className="ml-2">${selectedSoftware.amount}</span>
                  </div>
                  <div className="flex">
                    <strong className="text-[#006CB5]">Purchase Date:</strong>
                    <span className="ml-2">
                      {new Date(
                        selectedSoftware.purchaseDate
                      ).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                  <div className="flex ml-4">
                    <strong className="text-[#006CB5]">Expiry Date:</strong>
                    <span className="ml-2">
                      {new Date(selectedSoftware.expiryDate).toLocaleDateString(
                        "en-US",
                        { day: "numeric", month: "long", year: "numeric" }
                      )}
                    </span>
                  </div>
                  <div className="flex">
                    <strong className="text-[#006CB5]">User ID:</strong>
                    <span className="ml-2">{selectedSoftware.userId}</span>
                  </div>
                  <div className="flex ml-4">
                    <strong className="text-[#006CB5]">
                      Software Password:
                    </strong>
                    <span className="ml-2">{selectedSoftware.password}</span>
                  </div>
                  <div className="flex col-span-2">
                    <strong className="text-[#006CB5]">Additional Info:</strong>
                    <span className="ml-2">
                      {selectedSoftware.additionalInfo}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>

        {/* Modal for confirmation before deleting software */}
        <Modal
          isOpen={confirmDeleteModalIsOpen}
          onRequestClose={closeConfirmDeleteModal}
          className="flex items-center justify-center p-4 h-full w-full z-50" // Added z-50 here
          overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 z-40"
          contentLabel="Confirm Delete"
        >
          <div
            ref={modalRef}
            className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-auto"
          >
            {/* Delete Icon */}
            <div className="flex justify-center mb-4">
              <i className="fas fa-trash-alt text-red-600 text-4xl"></i>{" "}
              {/* Font Awesome trash icon */}
            </div>

            <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
              Are You Sure for Delete?
            </h2>
            <p className="mb-4 text-center">
              Do you really want to delete this software record?
            </p>
            <div className="flex justify-center space-x-16">
              <button
                onClick={closeConfirmDeleteModal}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          className="absolute inset-0 flex items-center justify-center p-4 z-50"
          overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 z-40"
          contentLabel="Edit Software"
        >
          <div
            ref={editFormRef}
            className="w-full max-w-[1032px] mx-auto bg-white rounded-2xl shadow-md border border-gray-200"
            style={{
              borderRadius: "30px",
              maxHeight: "90vh",
              overflowY: "hidden",
            }} // Keep overflow hidden here
          >
            <div className="flex justify-between items-center p-6">
              <h2 className="text-2xl font-bold text-gray-700">
                Edit Software Info
              </h2>
              <button
                onClick={closeEditModal}
                className=" text-black w-8 h-8 rounded-full border-2 border-gray-600 flex items-center justify-center"
              >
                X
              </button>
            </div>
            <hr
              className="border-t border-gray-300 w-full"
              style={{ margin: "0" }}
            />{" "}
            {/* Adjusted here */}
            <div
              className="p-6 rounded-md ml-4"
              style={{
                maxHeight: "70vh", // Allow the inner form to scroll
                overflowY: "auto", // Enable scrolling for the inner content
              }}
            >
              <form onSubmit={handleEditSubmit}>
                {/* Input Fields */}
                <div className="flex gap-4 mb-4">
                  <div className="flex-1">
                    <label
                      htmlFor="softwareName"
                      className="block text-gray-700 text-sm font-medium mb-2"
                    >
                      Software Name
                    </label>
                    <input
                      type="text"
                      id="softwareName"
                      name="softwareName"
                      value={editFormData.softwareName}
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          softwareName: e.target.value,
                        })
                      }
                      placeholder="Enter software name"
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      required
                    />
                  </div>

                  <div className="flex-1">
                    <label
                      htmlFor="amount"
                      className="block text-gray-700 text-sm font-medium mb-2"
                    >
                      Amount
                    </label>
                    <input
                      type="number"
                      id="amount"
                      name="amount"
                      value={editFormData.amount}
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          amount: e.target.value,
                        })
                      }
                      placeholder="Enter amount"
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      required
                    />
                  </div>
                </div>

                <div className="flex gap-4 mb-4">
                  <div className="flex-1">
                    <label
                      htmlFor="purchaseDate"
                      className="block text-gray-700 text-sm font-medium mb-2"
                    >
                      Purchase Date
                    </label>
                    <input
                      type="date"
                      id="purchaseDate"
                      name="purchaseDate"
                      value={editFormData.purchaseDate}
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          purchaseDate: e.target.value,
                        })
                      }
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      required
                    />
                  </div>

                  <div className="flex-1">
                    <label
                      htmlFor="expiryDate"
                      className="block text-gray-700 text-sm font-medium mb-2"
                    >
                      Expiry Date
                    </label>
                    <input
                      type="date"
                      id="expiryDate"
                      name="expiryDate"
                      value={editFormData.expiryDate}
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          expiryDate: e.target.value,
                        })
                      }
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      required
                    />
                  </div>
                </div>

                <div className="flex gap-4 mb-4">
                  <div className="flex-1">
                    <label
                      htmlFor="userId"
                      className="block text-gray-700 text-sm font-medium mb-2"
                    >
                      User ID
                    </label>
                    <input
                      type="text"
                      id="userId"
                      name="userId"
                      value={editFormData.userId}
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          userId: e.target.value,
                        })
                      }
                      placeholder="Enter user ID"
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      required
                    />
                  </div>

                  <div className="flex-1">
                    <label
                      htmlFor="password"
                      className="block text-gray-700 text-sm font-medium mb-2"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordVisible ? "text" : "password"} // Toggle input type between text and password
                        id="password"
                        name="password"
                        value={editFormData.password}
                        onChange={(e) =>
                          setEditFormData({
                            ...editFormData,
                            password: e.target.value,
                          })
                        }
                        placeholder="Enter password"
                        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                        required
                      />
                      <button
                        type="button"
                        onClick={handlePasswordToggle}
                        className="absolute inset-y-0 right-6 flex items-center px-2 text-gray-600"
                      >
                        {passwordVisible ? (
                          <EyeSlashIcon className="h-5 w-5" />
                        ) : (
                          <EyeIcon className="h-5 w-5" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mb-4 mr-7">
                  <label
                    htmlFor="additionalInfo"
                    className="block text-gray-700 text-sm font-medium mb-2"
                  >
                    Additional Info
                  </label>
                  <textarea
                    id="additionalInfo"
                    name="additionalInfo"
                    value={editFormData.additionalInfo}
                    onChange={(e) =>
                      setEditFormData({
                        ...editFormData,
                        additionalInfo: e.target.value,
                      })
                    }
                    placeholder="Enter additional information"
                    rows="4"
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div className="flex justify-end   mr-14 mb-4 space-x-4">
                  <button
                    type="submit"
                    className="bg-[#006CB5] text-white px-10 py-2 rounded "
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={unauthorizedModalIsOpen}
          onRequestClose={closeUnauthorizedModal}
          className="absolute inset-0 flex items-center justify-center p-4"
          overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-50"
          contentLabel="Unauthorized Access"
        >
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-auto">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              Unauthorized Access
            </h2>
            <p className="mb-4">
              You are not authorized to perform this action.
            </p>
            <div className="flex justify-end">
              <button
                onClick={closeUnauthorizedModal}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ViewSoftware;
