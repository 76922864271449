import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import logo from '../../assets/CS24_new_logo_final_white.png';
import Cookies from 'js-cookie';

const SidebarNav = ({ isOpen, toggleSidebar }) => {

  const [isMobileView, setIsMobileView] = useState(false);
  const [userRole, setUserRole] = useState("");

  const [activeLink, setActiveLink] = useState('');
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // in seconds

        if (decodedToken.exp < currentTime) {
          // Token is expired, clear the cookie and redirect to login
          Cookies.remove("token");
          navigate("/");
        } else {
          // Token is valid, set user role
          setUserRole(decodedToken.role);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        Cookies.remove("token");
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);
 
 

  useEffect(() => {
    if (isMobileView && isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileView, isOpen]);



  const menuItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/34cab2c9f71db5e4bcffc57a043fd911339e5278f7868b9215fef2e23506d3a8?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Client Information",
      to: "/ViewClient",
      roles: ["social", "web", "admin", "user"],
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ed07ad6de10ac398750a90a91c724eab0293801ebc734dd7157742be3c1c8dc8?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Social Media",
      to: "/ViewSocialMedia",
      roles: ["social", "web", "admin", "user"],
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f8139487fef4c90ca4068b0d76dbb475079c1a1bea50e8d08f0daef419b7151e?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Software",
      to: "/ViewSoftware",
      roles: ["social", "web", "admin", "user"],
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/82aa7e28a1d1d09c117643b12456b6e4baee6ae4ff382a6ac5aba8acd71482cf?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Event",
      to: "/ViewEvent",
      roles: ["social", "web", "admin", "user"],
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2f1f525d4dc5773a742680c65973b9b4da73a057ce33d23b644ac3d908d904f7?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Task",
      to: "/ViewTask",
      roles: ["social", "web", "admin", "user"],
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/81e2866bd187a4a6f4a4d92f87403877f1b8bf5433d0055d3abdb6f1712b7e29?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Report",
      to: "/ViewReport",
      roles: ["social", "web", "admin", "user"],
    },
  ];

  return (
    <nav
  className={`bg-[#006CB5] top-0 shadow-gray-400 p-6 flex flex-col items-start space-y-4 fixed left-0 h-screen transition-transform duration-600 ease-in-out lg:translate-x-0 lg:w-64 w-50 sm:w-56 hide-scrollbar`}
  style={{ zIndex: 9999 }}
  ref={dropdownRef}
>
  {/* Logo section at the top of the sidebar */}
  <div className="w-full flex mb-6 justify-center items-center">
    <Link to="/dashboard">
      <img src={logo} alt="Logo" className="w-40 h-23" />
    </Link>
  </div>

  {/* Menu Items */}
  {menuItems.map(({ icon, label, to, roles }) => (
        <Link
          key={label}
          to={roles.includes(userRole) ? to : "#"}
          className={`group flex items-center font-bold py-2 px-4 rounded hover:text-gray-100 transition duration-200 w-full text-left text-[#1FAAE2] ${
            activeLink === label ? "active-label" : ""
          }`}
          onClick={(e) => {
            if (!roles.includes(userRole)) {
              e.preventDefault();
            } else {
              setActiveLink(label);
              if (isMobileView) toggleSidebar();
            }
          }}
        >
          <img
            src={icon}
            alt={label}
            className="w-6 h-6 mr-2 group-hover:scale-110 transition-transform duration-200"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://via.placeholder.com/24";
            }}
            style={{ filter: "brightness(0) invert(1)" }}
          />
          {label}
        </Link>
      ))}

  {/* Admin Login and Logout links at the bottom */}
  <div className="flex-grow" /> {/* This pushes the following content to the bottom */}
  <div className="pt-10">
    <Link
      to="/admin/login"
      className="flex items-center font-bold py-2 px-4 rounded hover:text-gray-100 transition duration-200 w-full text-left text-[#1FAAE2]"
      onClick={toggleSidebar}
    >
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/636567b0b4c7df333ae28911987757683a314d9591a4fcaf26d9bbe64090181d?apiKey=208ba67bf72b486f9889b172fa1cace6"
        alt="Admin Login"
        className="w-6 h-6 mr-2"
        style={{ filter: "brightness(0) invert(1)" }}
      />
      <span>Admin Login</span>
    </Link>

    <Link
      to="/logout"
      className="flex items-center font-bold pt-6 text-[#1FAAE2] py-2 px-4 rounded hover:text-gray-100 transition duration-200 w-full text-left"
      onClick={toggleSidebar}
    >
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/447eb24ec265a3d93c378652e93d62d53da27c2ed079220beb15f4af6a173258?apiKey=208ba67bf72b486f9889b172fa1cace6"
        alt="Log Out"
        className="w-6 h-6 mr-2"
        style={{ filter: "brightness(0) invert(1)" }}
      />
      <span>Log Out</span>
    </Link>
  </div>
</nav>

  );
};

export default SidebarNav;
