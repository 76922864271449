import React, { useState, useEffect } from "react";
import axios from "axios";
import API_BASE_URL from "../../ApiConfig/ApiConfig";
const TaskForm = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [date, setDate] = useState(""); // Initialize as empty string
  const [post, setPost] = useState("");
  const [video, setVideo] = useState("");
  const [isPaid, setIsPaid] = useState(true);

  const [additionalInfo, setAdditionalInfo] = useState("");

  const [errors, setErrors] = useState({
    companyName: "",
    date: "",
    post: "",
    video: "",
  });

  useEffect(() => {
    // Fetch clients from the backend
    axios
      .get(`${API_BASE_URL}/tasks/Search`)
      .then((response) => {
        const companyNames = response.data.map((client) => client.companyName); // Extract client names
        setClients([...companyNames]);
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
      });
  }, []);

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!selectedClient || selectedClient === "Select Client") {
      errors.companyName = "Please select a client.";
      valid = false;
    }
    if (!date) {
      errors.date = "Date is required.";
      valid = false;
    }
    if (!post) {
      errors.post = "Post is required.";
      valid = false;
    }
    if (!video) {
      errors.video = "Video is required.";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    // Handle form submission
    const taskData = {
      companyName: selectedClient,
      date,
      post: Number(post),
      video: Number(video),
      completePost: Number(),
      completeVideo: Number(),
      additionalInfo,
      isPaid,
    };

    axios
      .post(`${API_BASE_URL}/tasks/create`, taskData)
      .then((response) => {
        console.log("Task saved:", response.data);
        alert("Success: Task saved successfully!");
        // Reset form fields
        setSelectedClient("");
        setDate("");
        setPost("");
        setVideo("");
        setIsPaid(true);
        setAdditionalInfo("");
        setErrors({});
      })
      .catch((error) => {
        console.error("Error saving task:", error);
        alert("Error: Failed to save task. Please try again.");
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="min-w-full mx-auto md:mt-4 mt-10 p-6 bg-white rounded-3xl shadow-2xl"
      style={{
        maxWidth: "900px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 20px",
      }}
    >
      <h2 className="text-2xl font-bold text-gray-700 mb-4">Create Task</h2>
      <hr className="border-t mb-6 border-gray-300 mx-[-24px]" />

      <div className="p-6 rounded-md  border-gray-300">
        {/* Company Name */}
        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">
            Company Name
          </label>
          <select
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 ${
              errors.companyName ? "border-red-500" : "border-gray-300"
            }`}
          >
            <option value="">Select Company Name</option>
            {clients.map((client, index) => (
              <option key={index} value={client}>
                {client}
              </option>
            ))}
          </select>
          {errors.companyName && (
            <p className="text-red-500 text-sm mt-1">{errors.companyName}</p>
          )}
        </div>

        {/* Date, Post, Video */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div>
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Date
            </label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 ${
                errors.date ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.date && (
              <p className="text-red-500 text-sm mt-1">{errors.date}</p>
            )}
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Target Post
            </label>
            <input
              type="number"
              value={post}
              onChange={(e) => setPost(e.target.value)}
              placeholder="Enter post"
              className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 ${
                errors.post ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.post && (
              <p className="text-red-500 text-sm mt-1">{errors.post}</p>
            )}
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-700 mb-2">
              {" "}
              Target Video
            </label>
            <input
              type="number"
              value={video}
              onChange={(e) => setVideo(e.target.value)}
              placeholder="Enter video"
              className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 ${
                errors.video ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.video && (
              <p className="text-red-500 text-sm mt-1">{errors.video}</p>
            )}
          </div>
        </div>
        {/* Is Paid Toggle */}
        {/* Enhanced Is Paid Toggle */}
        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">
            Status
          </label>
          <div className="flex items-center">
            <div
              onClick={() => setIsPaid(!isPaid)}
              className={`relative w-20 h-10 bg-gray-300 rounded-full cursor-pointer transition-all duration-300 ${
                isPaid ? "bg-green-500" : "bg-red-500"
              }`}
            >
              <span
                className={`absolute top-1 left-1 w-8 h-8 bg-white rounded-full shadow-md transition-transform duration-300 ${
                  isPaid ? "transform translate-x-10" : ""
                }`}
              ></span>
            </div>
            <span className="ml-4 text-lg font-semibold text-gray-700">
              {isPaid ? "Paid" : "Free"}
            </span>
          </div>
        </div>

        {/* Additional Info */}
        <div className="mb-4">
          <label className="block text-lg font-medium text-gray-700 mb-2">
            Additional Info
          </label>
          <textarea
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder="Enter additional info"
            className="block w-full p-4 border border-gray-300 shadow-sm outline-none focus:ring-1 focus:ring-gray-300 rounded-lg resize-y"
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-end ">
        <button
          type="submit"
          className="bg-[#006CB5] text-white font-semibold py-2 px-8 rounded-[8px] hover:bg-blue-700 transition duration-300 shadow-lg w-full sm:w-auto"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default TaskForm;
