import React, { useEffect, useState, useRef, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import EditClientForm from "./EditClientForm";
import API_BASE_URL from "../../ApiConfig/ApiConfig";
import Cookies from "js-cookie";
import "@fortawesome/fontawesome-free/css/all.min.css";

const ViewClient = () => {
  const [clientRecords, setClientRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [deleteClientID, setDeleteClientID] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [message, setMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("Active");

  const recordsPerPage = 10;

  const startIndex = (currentPage - 1) * recordsPerPage;

  const modalRef = useRef(null);
  const editFormRef = useRef(null);

  const fetchClientRecords = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/client-information/fetch`
      );
      if (response.ok) {
        const data = await response.json();
        setClientRecords(data);
        setFilteredRecords(data);
      }
    } catch (error) {
      console.error("Error fetching client records:", error);
    }
  };

  useEffect(() => {
    const filtered = clientRecords.filter((client) => {
      // Check if the company name matches the search term
      const matchesSearch =
        client.clientID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.companyName?.toLowerCase().includes(searchTerm.toLowerCase());

      // Check if the client status matches the selected status filter
      const matchesStatus =
        statusFilter === "Closed"
          ? client.projectStatus.includes("Closed")
          : !client.projectStatus.includes("Closed");

      // Return true if both conditions are met
      return matchesSearch && matchesStatus;
    });

    // Update the filtered records state
    setFilteredRecords(filtered);

    // Reset current page to 1 when the status filter changes
    setCurrentPage(1);
  }, [searchTerm, statusFilter, clientRecords]);

  const formatStatus = (status) => {
    if (Array.isArray(status) && status.length > 0) {
      return status.join(", ");
    }
    return "No Status Available";
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserRole(decodedToken.role);
    }

    fetchClientRecords();
  }, []);

  const handleViewClick = (client) => {
    if (userRole === "user" || userRole === "social") {
      setMessage("You are not allowed to perform this action.");
    } else {
      setClientData(client);
      setIsModalOpen(true);
    }
  };

  const handleEditClick = (client) => {
    if (userRole === "user" || userRole === "social") {
      setMessage("You are not allowed to perform this action.");
    } else {
      setClientData(client);
      setIsEditFormOpen(true);
    }
  };

  const handleDeleteClick = (client) => {
    if (userRole === "user" || userRole === "social") {
      setMessage("You are not allowed to perform this action.");
    } else {
      if (client) {
        setDeleteClientID(client.clientID);
        setShowDeleteConfirmModal(true);
      } else {
        console.error("Client data is undefined");
      }
    }
  };

  const confirmDeleteClient = async () => {
    if (!deleteClientID) return;

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/client-information/${deleteClientID}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setClientRecords((prevRecords) =>
          prevRecords.filter((client) => client.clientID !== deleteClientID)
        );
      } else {
        const errorData = await response.json();
        console.error("Failed to delete client:", errorData.message);
        setMessage("Failed to delete client.");
      }
    } catch (error) {
      console.error("Error deleting client:", error);
      setMessage("An error occurred while deleting the client.");
    } finally {
      setShowDeleteConfirmModal(false);
      setDeleteClientID(null);
    }
  };

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setClientData(null);
    fetchClientRecords(); // Refetch records after closing the modal
  }, []);

  const closeEditForm = useCallback(() => {
    setIsEditFormOpen(false);
    setClientData(null);
    fetchClientRecords(); // Refetch records after closing the edit form
  }, []);

  const closeMessageModal = () => {
    setMessage(null);
  };

  const formatDate = (dateObj) => {
    if (dateObj) {
      const dateString = dateObj.$date || dateObj;
      return new Date(dateString).toLocaleDateString();
    }
    return "Date not available";
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (modalRef.current && !modalRef.current.contains(event.target)) ||
        (editFormRef.current && !editFormRef.current.contains(event.target))
      ) {
        closeModal();
        closeEditForm();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal, closeEditForm]);

  const totalPages = Math.ceil(filteredRecords.length / recordsPerPage);
  const displayedRecords = filteredRecords.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  const handlePageChange = (page) => setCurrentPage(page);

  const handleAddClientClick = () => {
    if (userRole === "web" || userRole === "admin") {
      // Redirect to the /clientform page
      window.location.href = "/clientForm";
    } else if (userRole === "user" || userRole === "social") {
      // Show unauthorized message
      setMessage("You are not authorized to access this page.");
    }
  };

  return (
    <div
      className="min-w-full md:mt-4 mt-10 h-472 p-6 bg-white rounded-3xl shadow-2xl md:block"
      style={{
        maxWidth: "900px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 20px",
      }}
    >
      <div className="flex items-center justify-between mb-6">
        {/* Left Side: Client Information */}
        <h1 className="text-xl sm:text-2xl font-bold text-[#292D32]  text-left">
          Client Records
        </h1>
        {/* Add Client Button */}
        <button
          onClick={handleAddClientClick}
          className="bg-[#006CB5] text-white font-bold py-2 px-4 rounded-md shadow-md hover:bg-[#0056a3] transition duration-300"
        >
          Add Client
        </button>

        {/* Center: Search Bar */}
        <div className="relative ml-64 w-[25%]">
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
            <i className="fas fa-search"></i>
          </div>

          <input
            type="text"
            placeholder="Search Company"
            className="border p-1 pl-10 pr-10 rounded-full w-full shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
            style={{ animation: "none" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="flex items-center mr-3 space-x-4">
          <label className="flex items-center space-x-1 text-sm">
            <input
              type="radio"
              className="form-radio cursor-pointer h-4 w-4 text-blue-600 accent-[#006CB5] focus:outline-none"
              checked={statusFilter === "Active"}
              onChange={() => setStatusFilter("Active")}
            />
            <span className="text-[#B5B7C0] font-bold">Active</span>
          </label>

          <label className="flex items-center space-x-1 text-sm">
            <input
              type="radio"
              className="form-radio cursor-pointer h-4 w-4 text-blue-600 accent-[#006CB5] focus:outline-none"
              checked={statusFilter === "Closed"}
              onChange={() => setStatusFilter("Closed")}
            />
            <span className="text-[#B5B7C0] font-bold">Closed</span>
          </label>
        </div>
      </div>
      <hr className="border-t border-gray-300 mx-[-24px] " />

      {clientRecords.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-md shadow-sm">
            <thead className="">
              <tr>
                <th className="p-4 text-sm font-semibold text-[#B5B7C0] text-left">
                  Company Name
                </th>
                <th className="p-4 text-sm font-semibold text-[#B5B7C0] text-left">
                  Technology
                </th>
                <th className="p-4 text-sm font-semibold text-[#B5B7C0] text-left">
                  Project Assign To
                </th>
                <th className="p-4 text-sm font-semibold text-[#B5B7C0] text-left">
                  Project Type
                </th>
                <th className="p-2 border-b font-semibold text-[#B5B7C0] pl-7 text-left ">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {displayedRecords.map((client) => (
                <tr key={client.clientID} className="border-t hover:bg-gray-50">
                  <td className="p-4 text-sm font-semibold text-gray-700">
                    {client.companyName}
                  </td>
                  <td className="p-4 text-sm font-semibold text-gray-700">
                    {client.technology}
                  </td>
                  <td className="p-4 text-sm font-semibold text-gray-700">
                    {client.projectAssigned}
                  </td>
                  <td className="p-4 text-sm font-semibold text-gray-700">
                    {formatStatus(client.projectStatus)}
                  </td>
                  <td className="p-4 text-sm  space-x-4 text-blue-500">
                    <button
                      onClick={() => handleViewClick(client)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <i className="fas fa-eye text-sm"></i> {/* View Icon */}
                    </button>
                    <button
                      onClick={() => handleEditClick(client)}
                      className="text-[#008767] hover:text-green-700"
                    >
                      <i className="fas fa-edit text-sm"></i> {/* Edit Icon */}
                    </button>
                    <button
                      onClick={() => handleDeleteClick(client)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <i className="fas fa-trash-alt text-sm"></i>{" "}
                      {/* Delete Icon */}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600 mt-4">
          No client records found.
        </p>
      )}

      <div className="flex justify-between items-center mt-4">
        <p className="text-sm text-gray-600">
          Showing {startIndex + 1} to{" "}
          {Math.min(startIndex + recordsPerPage, filteredRecords.length)} of{" "}
          {filteredRecords.length} entries
        </p>
        <div className="flex space-x-2">
          {/* Previous button */}
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-2 py-1 font-bold bg-gray-200 rounded-md text-sm"
          >
            {"<"}
          </button>

          {/* Page numbers with ellipsis */}
          {Array.from({ length: totalPages }, (_, i) => i + 1)
            .filter((page) => {
              return (
                page === 1 || // Always show the first page
                page === totalPages || // Always show the last page
                page <= 3 ||
                (page >= currentPage - 1 && page <= currentPage + 1) // Show pages around current page
              );
            })
            .map((page, index, filteredPages) => (
              <React.Fragment key={page}>
                {index > 0 && page !== filteredPages[index - 1] + 1 && (
                  <span className="px-1 py-1 text-sm">...</span> // Show ellipsis for non-consecutive pages
                )}
                <button
                  onClick={() => handlePageChange(page)}
                  className={`px-2 py-1 rounded-md text-sm ${
                    currentPage === page
                      ? "bg-[#006CB5] text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {page}
                </button>
              </React.Fragment>
            ))}

          {/* Next button */}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-2 py-1 font-bold bg-gray-200 rounded-md text-sm"
          >
            {">"}
          </button>
        </div>
      </div>

      {isModalOpen && clientData && (
        <div className="fixed inset-0 flex pl-20 items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
          <div
            ref={modalRef}
            className="relative bg-white p-6 md:p-8 rounded-lg shadow-lg max-w-4xl w-full h-auto max-h-[90vh] overflow-y-auto scrollbar-custom"
          >
            {/* Close Button at Top-Right */}
            <button
              className="absolute top-8 pb-2 right-8 text-black hover:text-gray-700 focus:outline-none text-4xl font-semibold border border-black rounded-full w-7 h-7 flex items-center justify-center"
              onClick={closeModal}
            >
              &times;
            </button>

            <h2 className="text-xl md:text-2xl font-bold mb-6 text-center">
              Client Details
            </h2>
            {/* Client Details Section */}
            <div className="border border-dashed border-gray-300 p-4 rounded-md mb-3">
              <div className="grid ml-20 grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <strong className="font-medium text-blue-500">
                    Company Name:
                  </strong>{" "}
                  {clientData.companyName || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-blue-500">
                    Client Name:
                  </strong>{" "}
                  {clientData.clientName || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-blue-500">
                    Client ID:
                  </strong>{" "}
                  {clientData.clientID || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-blue-500">
                    Project Type:
                  </strong>{" "}
                  {clientData.projectType || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-blue-500">
                    Client Email:
                  </strong>{" "}
                  {clientData.clientEmail || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-blue-500">
                    Phone Number:
                  </strong>{" "}
                  {clientData.clientPhone || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-blue-500">
                    Project Assign To:
                  </strong>{" "}
                  {clientData.projectAssigned || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-blue-500">
                    Project Start Date:
                  </strong>{" "}
                  {formatDate(clientData.startDate) || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-blue-500">
                    Technology:
                  </strong>{" "}
                  {clientData.technology || "N/A"}
                </div>
              </div>
            </div>

            {/* Domain & Hosting Overview Section */}
            <h3 className="text-lg text-[#292D32] font-extrabold mb-3 text-center">
              Domain & Hosting Overview
            </h3>
            <div className="border border-dashed border-gray-300 p-4 rounded-md mb-4">
              <div className="grid ml-20 grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <strong className="font-medium text-[#006CB5]">
                    Domain Name:
                  </strong>{" "}
                  {clientData.domainName || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-[#006CB5]">
                    Hosting By:
                  </strong>{" "}
                  {clientData.hostingBy || "Purchased by Client"}
                </div>
                <div>
                  <strong className="font-medium text-[#006CB5]">
                    Domain By:
                  </strong>{" "}
                  {clientData.domainBy || "Purchased by Client"}
                </div>
                <div>
                  <strong className="font-medium text-[#006CB5]">
                    Server Name:
                  </strong>{" "}
                  {clientData.serverName || "N/A"}
                </div>
              </div>
              {/* Hosting Credentials */}
              <div className="ml-20">
                <strong className="font-bold mb-4">Hosting Credentials:</strong>
                <div>
                  <strong className="font-medium text-[#006CB5]">
                    User ID:
                  </strong>{" "}
                  {clientData.hostingUserId || "N/A"}
                </div>
                <div>
                  <strong className="font-medium text-[#006CB5]">
                    Password:
                  </strong>{" "}
                  {clientData.hostingPassword || "N/A"}
                </div>
              </div>
            </div>

            {/* Credentials and Validity Section */}
            <h3 className="text-lg text-[#292D32] font-extrabold  mb-3 text-center">
              Credentials and Validity
            </h3>
            <div className="border border-dashed border-gray-300 p-4 rounded-md mb-4">
              <div className="grid ml-20 grid-cols-1 md:grid-cols-2 gap-4">
                {/* WordPress Credentials */}
                <div className="">
                  <strong className="font-bold">WordPress Credentials:</strong>
                  <div>
                    <strong className="font-medium text-blue-500">
                      User ID:
                    </strong>{" "}
                    {clientData.wordPressUserId || "N/A"}
                  </div>
                  <div>
                    <strong className="font-medium text-blue-500">
                      Password:
                    </strong>{" "}
                    {clientData.wordPressPassword || "N/A"}
                  </div>
                </div>

                {/* Annual Maintenance */}
                <div className="">
                  <strong className="font-bold">Annual Maintenance:</strong>
                  <div>
                    <strong className="font-medium text-blue-500">
                      Status:
                    </strong>{" "}
                    {clientData.annualMaintenance || "N/A"}
                  </div>
                  <div>
                    <strong className="font-medium text-blue-500">
                      Start Date:
                    </strong>{" "}
                    {formatDate(clientData.annualMaintenanceStartDate) || "N/A"}
                  </div>
                  <div>
                    <strong className="font-medium text-blue-500">
                      Expiry Date:
                    </strong>{" "}
                    {formatDate(clientData.annualMaintenanceExpiryDate) ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>

            {/* Close Button */}
          </div>
        </div>
      )}

      {isEditFormOpen && clientData && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
          <div
            ref={editFormRef}
            className="bg-white rounded-lg shadow-lg w-[1050px] h-3/4 overflow-y-auto relative"
          >
            <button
              className="absolute top-4 right-4 cursor-pointer z-50 text-gray-600 hover:text-gray-800 focus:outline-none text-lg border border-gray-300 rounded-full w-8 h-8 font-bold flex items-center justify-center"
              onClick={closeEditForm}
              aria-label="Close"
            >
              &times;
            </button>

            <EditClientForm clientData={clientData} onClose={closeEditForm} />
          </div>
        </div>
      )}

      {showDeleteConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
          <div className="bg-white p-5 sm:p-6 rounded-lg shadow-lg w-full max-w-xs sm:max-w-md">
            <div className="flex justify-center mb-4">
              <i className="fas fa-trash-alt text-red-500 text-4xl"></i>{" "}
              {/* Delete icon */}
            </div>
            <h2 className="text-lg sm:text-xl pt-3 font-bold text-gray-800 mb-1 text-center">
              Are You Sure for Delete?
            </h2>
            <p className="text-sm sm:text-base pt-5 text-gray-600 text-center mb-4">
              Do you really want to delete this client record?
            </p>
            <div className="flex justify-center mt-7 space-x-12">
              {" "}
              {/* Centering the buttons */}
              <button
                className="bg-[#ECECEC] text-black font-bold px-10 py-2 rounded-md text-sm sm:text-base hover:bg-gray-400 transition duration-300"
                onClick={() => setShowDeleteConfirmModal(false)} // Cancel button
              >
                Cancel
              </button>
              <button
                className="bg-red-500 font-bold text-white px-10 py-2 rounded-md text-sm sm:text-base hover:bg-red-600 transition duration-300"
                onClick={confirmDeleteClient} // Delete button
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {message && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-semibold mb-4">Message</h2>
            <p className="mb-6">{message}</p>
            <div className="flex justify-end gap-4">
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none"
                onClick={closeMessageModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewClient;
